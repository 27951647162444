import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { googleKey } from '../../data/getDB';

export const PlacesContext = React.createContext({
  autocompleteService: {},
  placesService: {}
});

export default function GooglePlacesProvider({ children }) {
  const [loaded, setLoaded] = useState(false);
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [placesService, setPlacesService] = useState(null);

  useEffect(() => {
    if (!loaded) {
      if (!document.querySelector('#google-maps')) {
        const script = document.createElement('script');
        script.setAttribute('async', '');
        script.setAttribute('id', 'google-maps');
        script.onload = () => setLoaded(true);
        script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places`;
        document.querySelector('head').appendChild(script);
      }
    }
  }, [loaded])

  useEffect(() => {
    if (!autocompleteService && loaded && window.google) {
      setAutocompleteService(new window.google.maps.places.AutocompleteService());
      setPlacesService(new window.google.maps.places.PlacesService(document.createElement('div')))

    }
  }, [autocompleteService, loaded]);

  const getPredictionDetails = useCallback((placeId) => {
    const promise = new Promise((resolve, reject) => {
      placesService.getDetails({ placeId: placeId }, (result, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          resolve(result);
        } else {
          reject(status);
        }
      });
    });
    return promise;
  }, [placesService]);

  const providerValue = useMemo(() => ({ autocompleteService, placesService, getPredictionDetails }), [autocompleteService, placesService, getPredictionDetails])

  return (
    <PlacesContext.Provider value={providerValue}>
      {children}
    </PlacesContext.Provider>
  );
}