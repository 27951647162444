/*
 * File: c:\Core 3.0 Projects\ExtADSignup\ExtADSignup\ClientApp\src\afw\layout\ErrorLogger.js
 * Project: c:\Core 3.0 Projects\ExtADSignup\ExtADSignup\ClientApp
 * Created Date: Monday October 21st 2019
 * Author: Walton, Timothy
 * -----
 * Last Modified: Thursday January 16th 2020 12:13:56 pm
 * Modified By: the developer known as Walton, Timothy
 * -----
 */
import React, { useEffect } from 'react';
import { getBrowser } from '../../utils/common';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { useGlobalState } from '../../store/store';
import { enhancedReducer } from '../../utils/common';
import { When } from 'react-if';

const initialState = {
  notSupported: false
}

export default function ErrorContext(props) {

  const [state, setState] = React.useReducer(enhancedReducer, initialState);
  const { db, user } = useGlobalState();
  const browser = getBrowser();

  useEffect(() => {
    if (browser.name === 'IE') setState({ notSupported: true });
  }, [browser])

  useEffect(() => {
    window.onerror = (message, file, line, column, errorObject) => {
      try {
        column = column || (window.event?.errorCharacter);
        if (message.indexOf('dxswipestart') === -1) {
          db.invoke("submitErrorLog", {
            message: message?.substr(0, 2000),
            browser: `${browser.name}${browser.version}`,
            account_id: (user) ? user.account_id : null,
            error_type: 'javascript',
            create_dt: new Date()
          }).then(() => {
            console.log('Error log submitted successfully');
          })
        }
      } catch (err) {
        //Ignore
      }
      return false;
    };
  }, [user, db, browser])

  return (
    <div>
      <When condition={state.notSupported}>
        <div style={{ width: '100vw', height: '100vh', position: 'relative' }}>
          <Paper className="absolute-center" style={{ textAlign: 'left', padding: '10px', minWidth: '300px' }}>
            <Typography variant="h5">You are using {browser.name} {browser.version}. This browser is not supported. Use Chrome and set it as your default browser. Chrome can be downloaded <a href="https://www.google.com/chrome/">here</a></Typography>
            <Divider />
            <div style={{ textAlign: 'left', marginTop: '25px' }}>
              <Typography variant="h6"> Below are instructions for setting chrome as your default browser: </Typography>
              <Typography variant="body2">
                1. Once chrome is installed, open it.<br />
                2. Open your settings by clicking the 3 dots in the top right hand corner and then goto 'Settings'<br />
                3. Scroll down to 'Default Browser' and set it as your default browser<br />
                4. If you keep seeing this message, please contact <a href={`mailto:servicedesk@mdot.state.ms.us?subject=Unsupported IE11 Browser Issue ${window.location.href}`}>servicedesk@mdot.state.ms.us</a>
              </Typography>
            </div>
          </Paper>

        </div>
      </When>
      <When condition={!state.notSupported}>
        {props.children}
      </When>
    </div>
  );
}