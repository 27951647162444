/*
 * File: c:\Core 3.0 Projects\tasks\PreComm\ClientApp\src\data\baseModel.js
 * Project: c:\Core 3.0 Projects\tasks\PreComm\ClientApp
 * Created Date: Friday January 24th 2020
 * Author: Walton, Timothy
 * -----
 * Last Modified: Tuesday September 8th 2020 2:04:50 pm
 * Modified By: the developer known as Walton, Timothy
 * -----
 */
import produce, { immerable, setAutoFreeze } from 'immer';
setAutoFreeze(false);
// import update from 'immutability-helper';

class EntityModel {
    /* _ classes represent 'private' functions and properties. Do not touch. */
    _hasChanges = false;
    _originalJSON = ""
    _original = {}
    _initialized = false;
    constructor() {
        return new Proxy(this, {
            set(target, name, value) {
                target[name] = value;
                target._onPropertyChanged(name);
                return true;
            }
        })
    }
    _mergeDefaultProps(props) {
        if (typeof props === 'object') {
            for (let prop in props)
                if (this[prop] !== undefined) this[prop] = props[prop];
        }
        if (this['imports'] && typeof this['imports'] === 'function') this['imports']();
        this._setOriginal();
    }
    _setOriginal() {
        const { _originalJSON, _hasChanges, _initialized, exports, ...newObj } = this;
        this._originalJSON = JSON.stringify(newObj);
        this._original = this.toJS();
        this._hasChanges = false;
        this._initialized = true;
    }
    _onPropertyChanged(name) {
        if (this._initialized && (name !== '_initialized' && name !== '_hasChanges' && name !== '_original')) {
            const { _originalJSON, _hasChanges, _initialized, exports, ...current } = this;
            this._hasChanges = this._originalJSON !== JSON.stringify(current);
        }
    }
    hasChanges() {
        return this._hasChanges
    }
    resetChanges() {
        this._setOriginal();
    }
    original() {
        return this._original
    }
    toJS(proto) {
        let jsoned = {};
        let toConvert = proto || this;
        const props = Object.getOwnPropertyNames(toConvert)
        props.forEach((prop) => {
            const val = toConvert[prop];
            // don't include those
            if (prop === 'toJS' || prop[0] === '_' || prop === '$id' || prop === 'constructor' || prop === 'displayName' || (typeof val === 'function')) {
                return;
            }
            jsoned[prop] = val;
        });
        if (typeof toConvert.exports === 'function') {
            // jsoned = update(jsoned, { $merge: toConvert.exports(jsoned, toConvert.displayName) })
            jsoned = produce(jsoned, draft => {
                const convertedObj = toConvert.exports(jsoned, toConvert.displayName);
                for (const prop in convertedObj) draft[prop] = convertedObj[prop]
            })        
        }
        const inherited = Object.getPrototypeOf(toConvert);
        if (inherited !== null) {
            Object.keys(this.toJS(inherited)).forEach(key => {
                if (!!jsoned[key] || key === 'constructor' || key[0] === '_' || key === 'displayName' || key === 'toJS' || key === 'mergeDefaultProps')
                    return;
                if (typeof inherited[key] === 'function') {
                    jsoned[key] = inherited[key].bind(jsoned);
                    return;
                }
                jsoned[key] = inherited[key];
            });
        }
        return jsoned;
    }
}
EntityModel.prototype[immerable] = true;
export default EntityModel;