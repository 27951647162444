import React, { useCallback, useMemo, useState } from 'react';
import { useGlobalState } from '../../store/store';
import SnackbarPreset from '../SnackbarPreset/SnackbarPreset';

export const ValidationContext = React.createContext({
  handleAddValidator: () => { },
  validateGroup: () => { },
  handleRemoveValidator: () => { },
  createRules: () => []
});

const validators = [];
const MuiValidationEngine = ({ children }) => {
  const { vc } = useGlobalState();
  const [snackbarOptions, setSnackbarOptions] = useState({ open: false, message: "None", severity: 'error' });

  const handleAddValidator = useCallback((id, validate, currentValue) => {
    const exists = validators.findIndex(o => o.id === id);
    if (!~exists) validators.push({ id, validate, currentValue })
  }, []);

  const handleRemoveValidator = useCallback((id) => {
    const exists = validators.findIndex(o => o.id === id);
    if (~exists) validators.splice(exists, 1);
  }, []);

  const validateGroup = async (group = "default", customRules = []) => {
    const foundRules = { isValid: true, brokenRules: [] };
    const snackPack = { open: true, message: '', severity: 'success' };
    const results = await Promise.all(validators?.map(o => o.validate(o.currentValue)) || []);
    for (let i = 0; i < results.length; i++) {
      if (results[i]) {
        if (results[i].isValid === false && results[i].group === group) {
          foundRules.isValid = false;
          foundRules.brokenRules.push(...results[i].brokenRules);
        }
      }
    }
    for (let i = 0; i < customRules.length; i++) {
      if (customRules[i].brokenRule) {
        foundRules.isValid = false;
        foundRules.brokenRules.push({ message: customRules[i].message, type: 'required' });
      }
    }
    if (foundRules.isValid === false) {
      snackPack.message = foundRules.brokenRules.map(o => o.message).join(", ");
      snackPack.severity = 'error';
      const invalidElements = Array.from(document.querySelectorAll(`[aria-invalid="true"]`));
      if (invalidElements[0]) {
        window.scrollTo({ top: invalidElements[0].getBoundingClientRect().top + window.pageYOffset - 60, behavior: 'smooth' });
      }
      setSnackbarOptions(snackPack);
    }
    return foundRules;
  }

  const createRules = useCallback((fieldName, componentRef) => {
    if (vc) {
      const tableConfig = fieldName.split(".");
      const rules = vc.filter(o => o.table_name === tableConfig[0] && o.field_name === tableConfig[1]);
      for (let i = 0; i < rules.length; i++) {
        if (Boolean(rules[i].callback) && Boolean(componentRef?.[rules[i].callback])) {
          rules[i].validationCallback = componentRef[rules[i].callback];
        }
        if (rules[i].type === 'pattern' && rules[i].value !== null) {
          rules[i].value = new RegExp(rules[i].value);
        }
        if (rules[i].type === 'range' && rules[i].value !== null && typeof rules[i].value === 'string') {
          rules[i].value = JSON.parse(rules[i].value)
        }
      }
      return rules.map(o => ({
        type: o.type,
        message: o.message,
        validationCallback: o.validationCallback,
        value: o.value
      })).sort((a, b) => b.type.localeCompare(a.type));
    }
    return [];
  }, [vc]);

  const providerValue = useMemo(() => ({ validateGroup, handleAddValidator, handleRemoveValidator, createRules }), [handleAddValidator, handleRemoveValidator, createRules])

  return (
    <ValidationContext.Provider value={providerValue}>
      {children}
      <SnackbarPreset
        {...snackbarOptions}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setSnackbarOptions(prev => ({ ...prev, open: false }))}
      />
    </ValidationContext.Provider>
  )
}

export default MuiValidationEngine;