/*
 * File: c:\Core 3.0 Projects\Tasks\Tasks\ClientApp\src\afw\js\common.js
 * Project: c:\Core 3.0 Projects\Tasks\Tasks\ClientApp
 * Created Date: Monday October 21st 2019
 * Author: Walton, Timothy
 * -----
 * Last Modified: Monday August 10th 2020 12:21:37 pm
 * Modified By: the developer known as Walton, Timothy
 * -----
 */
//Cancelable Promise Wrapper
import seedrandom from 'seedrandom';
import devices from 'devextreme/core/devices';
import set from 'lodash.set';
import has from 'lodash.has';
import memoize from 'lodash.memoize';
import debounce from 'lodash.debounce';
import produce from "immer";
import { isMobile, isIPad13 } from 'react-device-detect';
import format from 'date-fns/format'
import React, { useRef, useEffect } from 'react';
import { differenceInDays, differenceInYears, endOfDay, isAfter, isBefore, isValid, parseISO, startOfDay } from 'date-fns';

export function createPromise() {
  let resolver;
  return [new Promise((resolve, reject) => {
    resolver = resolve
  }), resolver]
}

export function insertTokenEveryN(arr, token, n, fromEnd) {
  let a = arr.slice(0);
  let idx = fromEnd ? a.length - n : n;
  while ((fromEnd ? idx >= 1 : idx <= a.length)) {
    a.splice(idx, 0, token);
    idx = (fromEnd ? idx - n : idx + n + 1);
  }
  return a;
};

export function getFilterString(filter, fields) {
  let filterStr = "";
  const applyRecursive = (conditions) => {
    conditions.forEach((o, i) => {
      if (Array.isArray(o) && Array.isArray(o[0])) {
        filterStr += `(`;
        applyRecursive(o)
        filterStr += `)`;
      } else if (o !== 'or') {
        const field = fields.find(o2 => o2.dataField === o[0]);

        if (o[1] === 'between') {
          const formattedMinValue = isValid(o[2]?.[0]) ? standardDateFormat(o[2]?.[0], "MM/dd/yyyy") : o[2]?.[0];
          const formattedMaxValue = isValid(o[2]?.[1]) ? standardDateFormat(o[2]?.[1], "MM/dd/yyyy") : o[2]?.[1];
          if (requiredCheck(o[2]?.[0]) && requiredCheck(o[2]?.[1])) {
            filterStr += `[${field.fieldName}] Between ${formattedMinValue}-${formattedMaxValue}`;
          } else return;
        } else {
          const formattedValue = isValid(o[2]) && typeof (o[2]) !== 'number' ? standardDateFormat(o[2], "MM/dd/yyyy") : o[2];
          if (requiredCheck(o[2])) {
            filterStr += `[${field.fieldName}] ${o[1]} ${formattedValue}`;
          } else return;
        }
      }
      if (i !== conditions.length - 1) {
        if (o === "or") {
          if (filterStr.substring(filterStr.length - 5) === ' And ') {
            filterStr = filterStr.substring(0, filterStr.length - 5)
          }
          filterStr += ` Or `;
        } else {
          filterStr += ` And `;
        }
      }
    })
  }
  applyRecursive(filter[0]);
  return filterStr;
}

export function getOperations(dataSource, columns) {
  try {
    const dataTypes = dataSource.storeConfig?.['context'].ObjectModel[dataSource.storeConfig?.['entity']];

    const findOperations = (dataField, dataTypes) => {
      let ops = dataTypes[dataField]?.operations?.['$values'];
      let type = dataTypes[dataField]?.type;
      if (!Boolean(ops)) {
        const objs = dataField.split(".");
        let foundType = null;
        for (let i = 0; i < objs.length; i++) {
          foundType = dataSource.storeConfig?.['context'].ObjectModel[objs[i]];
          if (foundType) break;
        }
        objs.shift();
        const newPath = objs.join(".");
        if (foundType) {
          const result = findOperations(newPath, foundType);
          ops = result.ops;
          type = result.type
        }
      }
      return { ops, type };
    }

    return columns.reduce((prev, curr) => {
      if (curr.enableFilter !== false) {
        const { ops, type } = findOperations(curr.dataField, dataTypes);
        if (ops) {
          prev.push({ fieldName: curr.caption || curr.dataField, ops, value: undefined, type, dataField: curr.dataField })
        }
      }
      return prev;
    }, []);
  } catch (ex) {
    return null
  }
}
export function convertBetween(filter) {
  let converted = [];
  for (let i = 0; i < filter.length; i++) {
    converted[i] = filter[i];
    if (Array.isArray(filter[i])) {
      converted[i] = convertBetween(filter[i])
    } else if (filter[i] === 'between' && requiredCheck(filter[2]?.[0]) && requiredCheck(filter[2]?.[1])) {
      converted = [[filter[0], '>=', filter[2][0]], [filter[0], '<', filter[2][1]]];
      break;
    } else if (filter[i] === '=' && isValid(filter[2]) && typeof filter[2] !== 'number') {
      converted = [[filter[0], '>=', startOfDay(filter[2])], [filter[0], '<', endOfDay(filter[2])]];
      break;
    } else if (filter[i] === '<>' && isValid(filter[2]) && typeof filter[2] !== 'number') {
      converted = [[filter[0], '<', startOfDay(filter[2])], 'or', [filter[0], '>=', endOfDay(filter[2])]];
      break;
    }
  }
  return converted;
}
export function findBase(pathname) {
  //Weird change to react-router
  const firstIndex = pathname.indexOf('/');
  const secondIndex = pathname.split('/', 2).join('/').length;
  if (firstIndex === secondIndex) return pathname;
  else return pathname.substr(0, secondIndex);
}
export function createDistinct(arr) {
  return arr.filter((value, index) => {
    const _value = JSON.stringify(value);
    return index === arr.findIndex(obj => {
      return JSON.stringify(obj) === _value;
    });
  });
}
export function paginate(array, page_size, page_number) {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}
export function mergeInputProps(params, parentInputProps, parentinputProps) {
  const { InputProps, inputProps, ...paramsrest } = params
  const mergedInputProps = {
    ...InputProps,
    ...parentInputProps,
    endAdornment: parentInputProps?.endAdornment ? [React.cloneElement(InputProps.endAdornment, { key: 'mui-autocomplete-clear' }), parentInputProps.endAdornment] : InputProps.endAdornment,
    startAdornment: parentInputProps?.startAdornment ? InputProps.startAdornment ? [React.cloneElement(InputProps.startAdornment, { key: 'mui-autocomplete-search' }), parentInputProps.startAdornment] : parentInputProps?.startAdornment : InputProps.startAdornment,
  }
  const mergedinputProps = {
    ...inputProps,
    ...parentinputProps,
    name: params.id
  }
  paramsrest.InputProps = mergedInputProps;
  paramsrest.inputProps = mergedinputProps;
  return paramsrest;
}
export function convertFalseyToString(value) {
  return value === undefined || value === null ? '' : value.toString();
}
export function convertIfNumber(type, e) {
  if (type === 'number') e.target.value = parseInt(e.target.value, 0)
  if (type === 'decimal') e.target.value = parseFloat(e.target.value, 0)
  return e;
}
export function standardDateFormat(date, formatStr) {
  if (typeof date === 'string') date = new Date(date)
  return format(date || new Date(), formatStr || "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
}
export function addValidClass(e) {
  e.status === 'valid' ? e.element.classList.add("dx-valid") : e.element.classList.remove("dx-valid");
}
export function isValidDate(value) {
  return isValid(value) || isValid(parseISO(value));
}
export function isEmptyObject(obj) {
  return typeof obj === 'object' && !isValidDate(obj) && Object.keys(obj).length === 0;
}
export function isEmptyArray(arr) {
  return Array.isArray(arr) && arr.length === 0;
}
export function requiredCheck(value) {
  return (value !== undefined && value !== null && value !== '' && !isEmptyObject(value) && !isEmptyArray(value)) || isValidDate(value);
}
export function patternCheck(value, pattern) {
  return Boolean(value?.match(pattern));
}
export function minCheck(value, minValue) {
  if (value === null) return true;
  if (typeof value === 'string' && value.length >= parseFloat(minValue.toString(), 0)) return true;
  if (typeof value === 'number' && value >= parseFloat(minValue.toString(), 0)) return true;
  if (isValid(value) && isAfter(value, new Date(minValue))) return true;
  return false;
}
export function maxCheck(value, maxValue) {
  if (value === null) return true;
  if (typeof value === 'string' && value.length <= parseFloat(maxValue.toString(), 0)) return true;
  if (typeof value === 'number' && value <= parseFloat(maxValue.toString(), 0)) return true;
  if (isValid(value) && isBefore(value, new Date(maxValue))) return true;
  return false;
}
export function rangeCheck(value, { minValue, maxValue }) {
  if (value === null) return true;
  if (typeof value === 'string' && value.length <= maxValue && value.length >= minValue) return true;
  if (typeof value === 'number' && value <= maxValue && value >= minValue) return true;
  if (isValid(value) && isAfter(value, new Date(minValue) && isBefore(value, new Date(maxValue)))) return true;
  return false;
}
export function parseGoogleResponse(address_components, type) {
  if (Array.isArray(type)) {
    return type.map(o => address_components.find(o2 => o2.types.some(o3 => o3 === o)))
  } else return address_components.find(o => o.types.some(o2 => o2 === type));
}
export function emailCheck(value) {
  return String(value)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}
export function numberCheck(value) {
  if (typeof value === "number") return true;
  if (typeof str !== "string") return false;
  return !isNaN(value) && !isNaN(parseFloat(value))
}
export function scrollToIfNotVisible(ele, event) {
  if (event.type === 'keydown') {
    const container = ele.parentElement;
    const eleTop = ele.offsetTop;
    const eleBottom = eleTop + ele.clientHeight;

    const containerTop = container.scrollTop;
    const containerBottom = containerTop + container.clientHeight;

    // The element is fully visible in the container
    if (!(eleTop >= containerTop && eleBottom <= containerBottom)) {
      const modifier = event.key === 'ArrowDown' ? 1 : -1;
      container.scrollBy(0, modifier * ele.clientHeight);
    }
  }
}
export function getLocation() {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        resolve(position)
      }, (err) => {
        reject(err);
      }, { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 });
    } else reject("User Rejected");
  })
}
export function smartDate(date) {
  const ddiff = differenceInDays(date, new Date())
  const ydiff = differenceInYears(date, new Date())
  if (ddiff < 1) {
    return format(date, "hh:mm a")
  } else if (ydiff === 0) {
    return format(date, "MMM DD hh:mm a")
  } else {
    return format(date, "MM/DD/YY hh:mm a")
  }
}
export function isObject(obj) { return obj === Object(obj); }
export function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}
export function findMatchingSelectBoxElements(checkValue, filteredData, key) {
  let newValue = undefined;
  if (Array.isArray(checkValue)) {
    checkValue.forEach(item => {
      const foundValue = filteredData.find(o => {
        const val1 = (key ? o?.[key] : o);
        const val2 = (isObject(item) ? item[key] : item)
        return (val1 === val2);
      });
      if (foundValue) {
        if (!newValue) newValue = [];
        newValue.push(foundValue);
      }
    })
  } else {
    newValue = filteredData.find(o => (key ? o?.[key] : o) === checkValue);
  }
  return newValue;
}
export function arraysEqual(arr1, arr2, key) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    // Compare objects based on specific properties
    const compareValue1 = isObject(arr1[i]) ? arr1[i][key] : arr1[i];
    const compareValue2 = isObject(arr2[i]) ? arr2[i][key] : arr2[i];
    if (compareValue1 !== compareValue2) return false;
  }
  return true;
}
export function readFile(file, readType) {
  readType = readType || 'readAsDataURL';
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = function (readerResult) {
      resolve(readerResult.target.result);
    }
    reader.onerror = function (e) {
      reject(e);
    };

    reader[readType](file);
  })
}

export const makeCancelable = (promise) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => hasCanceled_ ? reject({ isCanceled: true }) : resolve(val),
      error => hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};
//Base 64 to arraybuffer
export function convertDataURIToBinary(dataURI) {
  let BASE64_MARKER = ';base64,';
  let base64Index = 0;
  let base64 = "";
  if (dataURI) {
    if (~dataURI.indexOf(BASE64_MARKER)) {
      base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
      base64 = dataURI.substring(base64Index);
    } else base64 = dataURI;
    let raw = window.atob(base64);
    let rawLength = raw.length;
    let array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }
}

export function getAllIndexes(arr, pred) {
  return arr.flatMap((v, i) => pred(v) ? i : [])
}

export function formatNavigation(data) {
  if (data?.v_security_navigation) {
    let uniqueGroups = data.v_security_navigation
      .filter((o) => o.category_name !== null) //Find grouped items
      .map(o => { return { nav_title: o.category_name, nav_id: o.nav_id, t_cd_navigation: data.v_security_navigation.filter(o2 => o2.category_name === o.category_name) } }) //Format data
      .filter((o, p, a) => a.map(o2 => o2.nav_title).indexOf(o.nav_title) === p); //Make it unique in the array.
    return [...data.v_security_navigation.filter(o => o.category_name === null), ...uniqueGroups]; //Merge ungrouped items.
  }
  return data;
}

export function activate(func, wait = 0, options = {}) {
  var mem = memoize(function () {
    return debounce(func, wait, options)
  }, options.resolver);
  return function () { mem.apply(this, arguments).apply(this, arguments) }
}

export function copy(o, fast) {
  var output, v, key;
  if (fast) return JSON.parse(JSON.stringify(o));
  output = Array.isArray(o) ? [] : {};
  for (key in o) {
    v = o[key];
    output[key] = (typeof v === "object" && v) ? copy(v) : v;
  }
  return output;
}
export function groupBy(list, keyGetter, convertToArray) {
  let map = new Map();
  if (list && keyGetter) {
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      (!collection) ? map.set(key, [item]) : collection.push(item);
    });
  }
  if (convertToArray) {
    const keys = Array.from(map.keys());
    return keys.map((o) => ({
      key: o,
      value: map.get(o) || []
    }));
  } else {
    return map;
  }
}
export function compareKeys(a, b) {
  var aKeys = Object.keys(a).sort();
  var bKeys = Object.keys(b).sort();
  return JSON.stringify(aKeys) === JSON.stringify(bKeys);
}
export function compareObjects(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}
export function MarkupFormatter(options) {
  let className = options.className || 'myClassName'
  let markup = options.markup || '';
  let result = `
import React, { Component } from 'react';

class ${className} extends Component {
  constructor(props){
    super(props)
  }
  render(){
    return(
${markup}
    );
  }
}
export default ${className}
`
  return result;
}
export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export function countOccurences(arr, val) {
  return arr.reduce((a, v) => (v === val ? a + 1 : a), 0);
}

export function getPath(obj, key, val, path) {
  path = path || "";
  let fullPath = "";
  for (var b in obj) {
    if (b === key && obj[b] === val) {
      return path;
      //} else if ((obj[b] && typeof obj[b] === "object" && obj[b].hasOwnProperty(key)) || Array.isArray(obj[b])) {
    } else if ((obj[b] && typeof obj[b] === "object") || Array.isArray(obj[b])) {
      let newPath = "";
      if (!isNaN(parseInt(b, 0))) {
        newPath = path.length ? `${path}[${b}]` : `[${b}]`;
      } else {
        newPath = path.length ? `${path}.${b}` : `${b}`;
      }
      fullPath = getPath(obj[b], key, val, newPath) || fullPath;
    }
  }
  return fullPath;
}
export function resolvePath(path, obj) {
  return path.split('.').reduce(function (prev, curr) {
    return prev ? prev[curr] : null
  }, obj)
}
export function randomColor(seed) {
  seedrandom(seed, { global: true })
  var letters = '012345'.split('');
  var color = '#';
  color += letters[Math.round(Math.random() * 5)];
  letters = '0123456789ABCDEF'.split('');
  for (var i = 0; i < 5; i++) {
    color += letters[Math.round(Math.random() * 15)];
  }
  return color;
}
export function upload(url, formData, responseType, uploadProgressCallback, downloadProgressCallback) {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.responseType = responseType || 'blob';
    xhr.withCredentials = true;
    xhr.onload = function () {
      if (xhr.status === 200) {
        if (xhr.responseType === 'blob') {
          let reader = new FileReader();
          reader.onload = function (evt) {
            resolve(evt.target.result);
          }
          reader.readAsArrayBuffer(xhr.response);
        } else {
          let response = JSON.parse(xhr.response);
          delete response['@odata.context'];
          resolve(response.value || response);
        }
      } else {
        reject(xhr);
      }
    }
    xhr.onerror = function (err) {
      reject(xhr, err);
    }
    xhr.upload.onprogress = function (evt) {
      if (uploadProgressCallback) uploadProgressCallback(evt);
    }
    xhr.onprogress = function (evt) {
      if (downloadProgressCallback) downloadProgressCallback(evt);
    }
    xhr.send(formData);
  })
}
export function download(url, params, requestType, responseType, progressCallback) {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.responseType = responseType || 'blob';
    xhr.open(requestType || "POST", url, true);
    xhr.withCredentials = true;
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.onload = function () {
      if (xhr.status === 200) {
        if (xhr.responseType === 'blob') {
          let reader = new FileReader();
          reader.onload = function (evt) {
            resolve(evt.target.result);
          }
          reader.readAsArrayBuffer(xhr.response);
        } else resolve(JSON.parse(xhr.response));
      } else {
        reject(xhr);
      }
    }
    xhr.onprogress = function (evt) {
      if (progressCallback) progressCallback(evt);
    }
    xhr.onerror = function (err) {
      reject(xhr, err);
    }
    xhr.send(JSON.stringify(params));
  })
}
export function image_download(url, readAsArrayBuffer, progressCallback) {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.open("GET", url, true);
    xhr.withCredentials = true;
    xhr.onload = function () {
      if (xhr.status === 200) {
        let reader = new FileReader();
        reader.onload = function (evt) {
          resolve(evt.target.result);
        }
        readAsArrayBuffer ? reader.readAsArrayBuffer(xhr.response) : reader.readAsDataURL(xhr.response);
      } else {
        reject();
      }
    }
    xhr.onerror = function (err) {
      reject(err);
    }
    xhr.onprogress = function (evt) {
      if (progressCallback) progressCallback(evt);
    }
    xhr.send();
  })
}
//src: https://stackoverflow.com/questions/1634748/how-can-i-delete-a-query-string-parameter-in-javascript
//Modified to accept array of parameters, and to remove the '?' character if completely destroying querystring.
export function removeURLParameters(url, parameters) {
  //prefer to use l.search if you have a location/link object
  for (let i = 0; i < parameters.length; i++) {
    const urlparts = url.split('?');
    if (urlparts.length >= 2) {
      const prefix = encodeURIComponent(parameters[i]) + '=';
      const pars = urlparts[1].split(/[&;]/g);

      //reverse iteration as may be destructive
      for (let j = pars.length; j-- > 0;) {
        //idiom for string.startsWith
        if (pars[j].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(j, 1);
        }
      }
      //if pars length = 0 then remove the '?' as there is no query string left.
      if (pars.length === 0) {
        //urlparts[1] looks like querystringparam1#navigation/param
        //Split on # to get '#' + hashSplit[1] := 'navigation/param' ;
        const hashSplit = urlparts[1].split('#');
        url = hashSplit.length === 2 ? urlparts[0] + "#" + hashSplit[1] : urlparts[0];
      } else url = urlparts[0] + '?' + pars.join('&');
    }
  }
  return url.split('#')[0];
}
export function rgbToHex(r, g, b) {
  function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
export function getBrowser() {
  let ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: (tem[1] || '') };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem !== null) { return { name: 'Opera', version: tem[1] }; }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) !== null) { M.splice(1, 1, tem[1]); }
  return {
    name: M[0],
    version: M[1]
  };
}
export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}
export function getIndicesOf(searchStr, str, caseSensitive) {
  let searchStrLen = searchStr.length;
  if (searchStrLen === 0) {
    return [];
  }
  let startIndex = 0, index, indices = [];
  if (!caseSensitive) {
    str = str.toLowerCase();
    searchStr = searchStr.toLowerCase();
  }
  while ((index = str.indexOf(searchStr, startIndex)) > -1) {
    indices.push(index);
    startIndex = index + searchStrLen;
  }
  return indices;
}
export function pad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
export function getTextColor(color) {
  if (color) {
    let rgb = {};
    if (~color.indexOf('#')) {
      //#000000
      rgb = hexToRgb(color);
    } else {
      //rgb(0,0,0)
      const rgbArr = color.replace(/[^\d,]/g, '').split(',');
      rgb = { r: rgbArr[0], g: rgbArr[1], b: rgbArr[2] }
    }
    return (rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114) > 186 ? 'black-text' : 'white-text';
  }
  return 'black-text';
}
export function parseDisplayExpr(displayExpr, value) {
  if (typeof displayExpr === 'function' && typeof value === 'object') return displayExpr(value);
}
export function pick(o, ...props) {
  return Object.assign({}, ...props.map(prop => ({ [prop]: o[prop] })));
}
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
export function formatBytes(a, b) { if (0 === a) return "0 Bytes"; var c = 1024, d = b || 2, e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"], f = Math.floor(Math.log(a) / Math.log(c)); return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f] }
export function enhancedReducer(state, updateArg) {
  // check if the type of update argument is a callback function
  if (updateArg.constructor === Function) {
    return { ...state, ...updateArg(state) };
  }

  // if the type of update argument is an object
  if (updateArg.constructor === Object) {
    // does the update object have _path and _value as it's keys
    // if yes then use them to update deep object values
    if (has(updateArg, "_path") && has(updateArg, "_value")) {
      const { _path, _value } = updateArg;
      let newState = { ...state };
      newState = produce(newState, draft => {
        set(draft, _path, _value);
      });
      return newState;
    } else {
      return { ...state, ...updateArg };
    }
  }
  if (Array.isArray(updateArg)) {
    let newState = { ...state };
    for (let i = 0; i < updateArg.length; i++) {
      if (has(updateArg[i], "_path") && has(updateArg[i], "_value")) {
        const { _path, _value } = updateArg[i];
        newState = produce(newState, draft => {
          set(draft, _path, _value);
        });
      }

    }
    return newState
  }
}
export function replaceAt(str, index, replacement) {
  return str.substr(0, index) + replacement + str.substr(index + replacement.length);
}
export function flattenNav(navData) {
  let flatList = [];
  if (navData) {
    for (let i = 0; i < navData.length; i++) {
      let o = navData[i];
      if (!o.t_cd_navigation) flatList.push(o);
      else flatList.push.apply(flatList, o.t_cd_navigation.map(o2 => o2));
    }
  }
  return flatList
}
const popupAnimation = devices.real().deviceType !== 'desktop' ? {
  show: {
    type: 'slide',
    from: { position: { my: 'left', at: 'right', of: window, offset: '0 0' } },
    to: { position: { my: 'right', at: 'left', of: window, offset: '0 0' } }
  },
  hide: {
    type: 'slide',
    from: { position: { my: 'right', at: 'left', of: window, offset: '0 0' } },
    to: { position: { my: 'left', at: 'right', of: window, offset: '0 0' } }
  }
} : {
  show: {
    type: 'pop',
    duration: 300,
    from: { scale: 0.55 }
  },
  hide: {
    type: 'pop',
    duration: 300,
    to: { opacity: 0, scale: 0.55 },
    from: { opacity: 1, scale: 1 }
  }
}
export function isMobileOrTablet() {
  return isMobile || isIPad13;
}
export { popupAnimation }
const environment = process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT : 'Development';
export { environment };