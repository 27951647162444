/*
 * File: c:\Core 3.0 Projects\ExtADSignup\ExtADSignup\ClientApp\src\views\Home.js
 * Project: c:\Core 3.0 Projects\ExtADSignup\ExtADSignup\ClientApp
 * Created Date: Monday October 21st 2019
 * Author: Walton, Timothy
 * -----
 * Last Modified: Friday August 7th 2020 12:56:24 pm
 * Modified By: the developer known as Walton, Timothy
 * -----
 */

import React, { useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Logo from '../images/mdotlogo.png';
import LightLogo from '../images/logo_light.png';
import { useTheme } from '@mui/styles';
import { useHistory } from 'react-router';
import { useCallback } from 'react';
import { setGlobalState, useGlobalState } from '../store/store';
import LinearProgress from '@mui/material/LinearProgress';
import { When } from 'react-if';
import CircularProgress from '@mui/material/CircularProgress';
import MuiTextBox from '../components/MuiTextBox/MuiTextBox';
import { isMobileOrTablet } from '../utils/common';
import DialogPreset from '../components/DialogPreset/DialogPreset';
import Box from '@mui/material/Box';
import SnackbarPreset from '../components/SnackbarPreset/SnackbarPreset';
import { Divider } from '@mui/material';

//Complex State example: https://levelup.gitconnected.com/handling-complex-form-state-using-react-hooks-76ee7bc937
const useStyles = makeStyles((theme) => ({
  background: theme.palette.primary.main,
  autoCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  centerArea: {
    marginTop: '10px',
    maxWidth: '500px',
    textAlign: 'center',
    width: '100%',
    position: 'relative'
  },
  contentPadding: {
    padding: '20px 0',
  },
  alignMiddle: {
    verticalAlign: 'middle',
    display: 'inline-block'
  },
  imageArea: {
    height: '75px'
  },
  loader: {
    position: 'absolute',
    top: '4px'
  }
}));

export default function SignIn() {
  const { OIDCSignIn, db } = useGlobalState();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackPack, setSnackPack] = useState({ snackOpen: false, message: "", severity: "error" });
  const textRef = useRef(null);

  const handleNavigate = useCallback(() => {
    history.push('/createaccount');
  }, [history]);

  const handleShow = useCallback(() => {
    setOpen(true)
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false)
  }, []);

  const handleCloseSnack = useCallback(() => {
    setSnackPack((prev) => ({ snackOpen: false, message: prev.message, severity: prev.severity }));
  }, []);

  const handleResetPW = useCallback(async () => {
    setLoading(true);
    setOpen(false);
    const email = textRef.current.value;
    try {
      await db.invoke("sendPasswordReset", { email });
    } catch (err) {
      setSnackPack({ snackOpen: true, message: "Unable to submit password reset, check your junk mail for any messages. Otherwise, please contact us at servicedesk@mdot.ms.gov", severity: "error" });
      setLoading(false);
      return;
    }
    setSnackPack({ snackOpen: true, message: `Reset Password sent to: ${email}`, severity: "success" });
    setLoading(false);
  }, [db])

  const handleSignIn = useCallback(async () => {
    setLoading(true);
    const UI = await OIDCSignIn(db);
    setGlobalState({ user: UI[0], navData: UI[0].v_ext_access_navigation })
  }, [OIDCSignIn, db])

  return (
    <Box>
      <div className={`absolute-center ${classes.centerArea} ${classes.autoCenter}`}>
        <LinearProgress variant="query" className={classes.loader} style={{ visibility: loading ? 'visible' : 'hidden' }} />
        <Paper className={classes.contentPadding}>
          <img src={theme.palette.mode === 'dark' ? Logo : LightLogo} className={classes.imageArea} alt="Mississippi Department of Transportation" />
          <Typography variant="h4" gutterBottom>
            MDOT External Account Management
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Sign in below, or request an MDOT External access account
          </Typography>
          <Divider />
          <Box sx={{ mt: 1 }}>
            <Button disabled={loading} className={classes.alignMiddle} onClick={handleSignIn} sx={{ mr: 0.5 }}>
              Sign in
            </Button>
            |
            <Button disabled={loading} className={classes.alignMiddle} onClick={handleNavigate} sx={{ mr: 0.5, ml: 0.5 }}>
              Request Access
            </Button>
            |
            <Button disabled={loading} className={classes.alignMiddle} onClick={handleShow} sx={{ ml: 0.5 }}>
              Forgot Password
            </Button>
          </Box>
        </Paper>
      </div>
      <SnackbarPreset
        key={'snack'}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackPack.snackOpen}
        autoHideDuration={12000}
        onClose={handleCloseSnack}
        message={snackPack.message}
        severity={snackPack.severity}
      />
      <DialogPreset
        id={"approval"}
        fullScreen={isMobileOrTablet()}
        open={open}
        slideTransition={isMobileOrTablet()}
        maxWidth={'lg'}
        onClose={handleClose}
        title={!loading ? "Enter Email to Reset Password" : null}
        allowClose={!loading}
      >
        <Box sx={{ p: 2, minWidth: isMobileOrTablet() ? undefined : "300px", maxWidth: '600px' }}>
          <When condition={loading}>
            <Box sx={{ textAlign: 'center', p: 1 }}>
              <CircularProgress sx={{ display: 'inline-block' }} />
              <Typography variant="subtitle1">Submitting Action...</Typography>
            </Box>
          </When>
          <When condition={!loading}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Please enter the email you used to sign up with below. An email will be triggered with instructions on how to proceed.
            </Typography>
            <MuiTextBox
              ref={textRef}
              label={"Email"}
              variant="filled"
              size={"small"}
              fullWidth
              enterKey={handleResetPW}
              inputProps={{ maxLength: 100, autoComplete: 'chrome-off' }}
            />
            <Box sx={{ textAlign: 'right', mt: 1 }}>
              <Button variant="outlined" disabled={loading} sx={{ display: 'inline-block', mr: 1 }} onClick={handleClose} color="error">
                Cancel
              </Button>
              <Button variant="outlined" disabled={loading} sx={{ display: 'inline-block' }} onClick={handleResetPW}>
                Submit
              </Button>
            </Box>
          </When>

        </Box>
      </DialogPreset>
    </Box>

  )
}