/*
 * File: c:\Core 3.0 Projects\ExtADSignup\ExtADSignup\ClientApp\src\navigation\Router.js
 * Project: c:\Core 3.0 Projects\ExtADSignup\ExtADSignup\ClientApp
 * Created Date: Monday October 21st 2019
 * Author: Walton, Timothy
 * -----
 * Last Modified: Monday July 27th 2020 4:55:37 pm
 * Modified By: the developer known as Walton, Timothy
 * -----
 */
import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import asyncComponent from "../components/AsyncComponent/AsyncComponent";
import { useGlobalState } from '../store/store';

const NotFound = asyncComponent(() => import(`../views/NotFound`));

const setupNavigation = (data) => {
  const pushPaths = (pathName, component, navParam) => {
    const arr = [];
    arr.push({
      path: pathName,
      component
    });
    if (navParam) {
      const paths = navParam.split(',');
      paths.forEach(path => {
        arr.push({
          path: `${pathName}${path}`,
          component
        });
      })
    }
    return arr;
  }
  const nav = data?.reduce((acc, o) => {
    const item = o.t_ext_access_navigation;
    acc = acc.concat(pushPaths(item.path, asyncComponent(() => import(`../views/${item.component}`)), item.params))
    return acc;
  }, []) || [];
  return nav;
}

export default function Router() {
  const { navData } = useGlobalState();
  const [navigation, setNavigation] = useState(setupNavigation(navData));

  useEffect(() => {
    setNavigation(setupNavigation(navData));
  }, [navData])
  return (
    <div>
      <Switch>
        {
          navigation.map(o => <Route key={o.path} exact path={o.path} component={o.component} />)
        }
        {navigation.length > 0 && <Route component={NotFound} />}
      </Switch>
    </div>
  )
}