import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import LinkDuo from '../LinkDuo/LinkDuo';
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  noBorder: {
    border: 'none !important',
    borderRadius: '0px !important'
  },
}));

export default function NavItem({ row, base }) {
  const active = row.path === base;
  const classes = useStyles();
  return (
    <ListItemButton component={LinkDuo} to={row.path} key={row.nav_id} selected={active} classes={{ selected: classes.noBorder }}>
      <ListItemIcon key={"lii" + row.nav_id} >
        <i key={row.nav_id} className="material-icons">{row.icon}</i>
      </ListItemIcon>
      <ListItemText key={"lit" + row.nav_id} primary={<span>{row.title}</span>} />
    </ListItemButton>
  );
}