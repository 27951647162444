import React, { useEffect, useCallback } from 'react';
import { enhancedReducer, compareObjects, removeURLParameters } from '../../utils/common';
import AppLoader from '../../components/AppLoader/AppLoader';
import { userstore, setGlobalState, useGlobalState } from '../../store/store';
import Oidc, { OidcClient } from 'oidc-client';

const settings = {
  authority: 'https://adfs.mdot.state.ms.us/adfs',
  metadata: {
    issuer: 'https://adfs.mdot.state.ms.us/adfs',
    authorization_endpoint: 'https://adfs.mdot.state.ms.us/adfs/oauth2/authorize',
    userinfo_endpoint: 'https://adfs.mdot.state.ms.us/adfs/userinfo',
    end_session_endpoint: `https://adfs.mdot.state.ms.us/adfs/oauth2/logout`,
    jwks_uri: 'https://adfs.mdot.state.ms.us/adfs/discovery/keys'
  },
  client_id: 'ae21e23c-0db6-4e86-85ad-9e04f74d586c',
  redirect_uri: window.location.origin + window.location.pathname,
  post_logout_redirect_uri: window.location.origin + window.location.pathname,
  response_type: 'id_token',
  scope: 'openid profile',
  silent_redirect_uri: window.location.origin + window.location.pathname,
  automaticSilentRenew: true,
  accessTokenExpiringNotificationTime: 4,
  filterProtocolClaims: true,
  loadUserInfo: false
};

const initialState = {
  errorText: "",
  loading: false,
  fetched: false
}

const OIDCSignIn = async (db) => {
  let ADFSInfo = null;
  let UI = null;
  const signingKeys = await db.invoke("getSigningKeys", null, "GET");
  settings.signingKeys = JSON.parse(signingKeys).keys;
  const client = new Oidc.UserManager(settings);
  try {
    //ADFS token callback
    ADFSInfo = await client.signinRedirectCallback();
    window.history.replaceState({}, document.title, removeURLParameters(window.location.href, ['authToken', 'client-request-id', 'id_token', 'state']));
    UI = await db.invoke("authenticate", { token: ADFSInfo.id_token });

  } catch (e) {
    //get ADFS token
    client.signinRedirect();
    throw new Error("No authorization found, redirecting...")
  }
  return UI;
}

const loadUser = async (db, userInfo) => {
  let UI = null;
  //Reload user info if logged in
  if (userInfo.length > 0 && userInfo[0].user_id !== 'Anonymous') {
    UI = await db.invoke("reloadUser");
    //Get anonymous permissions if not logged in
  } else if (userInfo.length === 0) {
    UI = await db.invoke("authenticate", { token: null });
    //Anonymous has 'logged in'
  } else UI = userInfo;
  return UI;
}

export default function UserContext({ children }) {
  const [state, setState] = React.useReducer(enhancedReducer, initialState);
  const { loading } = state;
  const { db } = useGlobalState();

  const silentLogout = useCallback(async (callback) => {
    const oidcClient = new OidcClient(settings);
    const request = await oidcClient.createSignoutRequest();
    const iframe = document.createElement('iframe');
    iframe.setAttribute("src", request.url);
    iframe.style.width = '0px';
    iframe.style.height = '0px';
    iframe.style.position = 'absolute';
    iframe.onload = () => {
      document.body.removeChild(iframe);
      callback();
    }
    document.body.appendChild(iframe);
  }, [])

  const signOut = useCallback(async () => {
    setState({ loading: true });
    await db.invoke("signOut");
    await userstore.clear();
    const UI = await loadUser(db, []);
    silentLogout(() => {
      setGlobalState({ user: UI[0], navData: UI[0].v_ext_access_navigation })
      setState({ loading: false });
    })
  }, [db, silentLogout]);

  const dispatchEvents = useCallback((UI) => {
    setGlobalState({ user: UI[0], navData: UI[0].v_ext_access_navigation, OIDCSignIn, signOut })
  }, [signOut]);

  const fetchUser = useCallback(async () => {
    setState({ fetched: true, loading: true })
    try {
      const userInfo = await userstore.load();
      let fetchedUser = null;
      //Local user Found
      if (window.location.hash.indexOf('id_token') > - 1) {
        dispatchEvents(await OIDCSignIn(db));
        setState({ loading: false })
      } else if (userInfo.length) {
        dispatchEvents(userInfo);
        setState({ loading: false })
      }
      fetchedUser = await loadUser(db, userInfo);
      if (fetchedUser?.length > 0 && !compareObjects(userInfo, fetchedUser)) {
        console.log("User info updated")
        dispatchEvents(fetchedUser);
      }
      setState({ loading: false })
    } catch (err) {
      console.log(err?.message?.toString());
      setState({ loading: false })
    }
  }, [dispatchEvents, db])

  useEffect(() => {
    if (!state.fetched) fetchUser();
  }, [state.fetched, fetchUser])

  return loading ? <AppLoader loadText="App Loading" /> : children;
}