/*
 * File: c:\Core 3.0 Projects\ExtADSignup\ExtADSignup\ClientApp\src\index.js
 * Project: c:\Core 3.0 Projects\ExtADSignup\ExtADSignup\ClientApp
 * Created Date: Thursday January 16th 2020
 * Author: Walton, Timothy
 * -----
 * Last Modified: Monday August 10th 2020 11:11:47 am
 * Modified By: the developer known as Walton, Timothy
 * -----
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { Router } from "react-router";
import { createRoot } from 'react-dom/client';
import { createBrowserHistory } from 'history';
import App from './layout/js/App';
import './layout/css/pace.css';
import Pace from 'pace-js-amd-fix';
import Logo from './images/mdotlogo.png';
import register from './utils/registerServiceWorker';
import MuiValidationEngine from './components/MuiValidator/MuiValidationEngine';
import GooglePlacesProvider from './components/MuiGooglePlacesAutocomplete/GooglePlacesProvider';

//If publishing to root, delete subdomain. 
//also go change getDB.js as there was no way to export this from index.js:
// const subdomain = {basename: '/twalton'}
// const history = createBrowserHistory(subdomain);
const history = createBrowserHistory();
Pace.start({ trackMethods: ['GET', 'POST', 'DELETE', 'PUT', 'PATCH'] });
const root = createRoot(document.getElementById('root'));
history.listen(() => {
  if (!window.location.hash) window.scrollTo(0, 0);
});

root.render(
  <Router history={history}>
    <GooglePlacesProvider>
      <MuiValidationEngine>
        <App name="ExtADSignup" logo={Logo} enablePWA={true} enableSignalR={false} navWidth={225} appName={"External Account Management"} />
      </MuiValidationEngine>
    </GooglePlacesProvider>
  </Router>);

//Specific IOS implementation
if (navigator.vendor === 'Apple Computer, Inc.') {
  register(null, false, true)
}