/*
 * File: c:\Core 3.0 Projects\ExtADSignup\ExtADSignup\ClientApp\src\data\parseEntityModel.js
 * Project: c:\Core 3.0 Projects\ExtADSignup\ExtADSignup\ClientApp
 * Created Date: Monday October 21st 2019
 * Author: Walton, Timothy
 * -----
 * Last Modified: Monday March 9th 2020 5:15:29 pm
 * Modified By: the developer known as Walton, Timothy
 * -----
 */
import EntityModel from './baseModel';
import overloads from './overloadEntityModel';
import { isEmpty, standardDateFormat } from '../utils/common';

//Set Default Property Values
function parseObjectData(obj) {
  let result = {};
  //C# Types to set defaults
  const TypeEnum = {
    'Int32': 0,
    'Int64': 0,
    'String': null,
    'DateTime': standardDateFormat(),
    'Boolean': false,
    'Decimal': 0.00,
    'ICollection`1': []
  }
  for (let prop in obj) {
    if (prop.indexOf("$") === -1) {
      const { nullable, type } = obj[prop];
      if (type) {
        const dataType = TypeEnum[type];
        result[prop] = nullable ? null : dataType !== undefined ? dataType : {};
      }
    }
  }
  return result;
}
//Checks for empty object
function isKeyMissing(table, keyValue, key, db) {
  const keyEnum = { 'Int32': 'number', 'Int64': 'number', 'String': 'string' }
  return keyEnum[db[table]._fieldTypes[key]] !== typeof keyValue;
}
export default function parseObjectModel(OM, db) {
  let classes = {}
  const tables = new RegExp(Object.keys(OM).sort((a, b) => b.length - a.length).join("|"), "g");
  for (let prop in OM) {
    //Set Default Property Values
    const props = parseObjectData(OM[prop]);
    const nameIt = (name, OMProps) => ({
      [name]: class extends EntityModel {
        displayName = name
        constructor(props) {
          super(props);
          props = props || {};
          //Merge the Entity Model Props with the provided props
          for (let prop in OMProps) {
            this[prop] = OMProps[prop];
            if (typeof (OMProps[prop]) === 'object' && OMProps[prop] && OMProps[prop]._value) this[prop] = OMProps[prop].toString();
            if (Array.isArray(this[prop]) && !Array.isArray(props[prop])) props[prop] = [];
          }
          this._mergeDefaultProps(props);
        }
        imports = () => {
          const self = this;
          for (let prop in self) {
            const matches = prop.match(tables);
            //If property is a table, or contains the table name (ex: SR_t_table_UPDATE_DATE)
            if (classes[prop] || matches) {
              const classProp = matches ? matches[0] : prop;
              //If navigation property 1-Many and also has value
              if (Array.isArray(self[prop]) && self[prop].length) {
                self[prop] = self[prop].map(o => new classes[classProp](o))
                //If navigation property 1-1 and also has value
              } else if (!Array.isArray(self[prop]) && !isEmpty(self[prop])) {
                self[prop] = new classes[classProp](self[prop]);
              }
            }
          }
          //User defined overloads
          if (overloads[this.displayName] && overloads[this.displayName].imports) {
            overloads[this.displayName].imports(this);
          }
        }
        exports = (self, displayName) => {
          let exportObj = {};
          const keyName = db[displayName]._key;
          for (let prop in self) {
            const matches = prop.match(tables);
            if (classes[prop] || matches) {
              //If navigation property 1-Many and also has value
              if (Array.isArray(self[prop]) && self[prop][0] && self[prop][0].toJS) {
                exportObj = { ...exportObj, ...{ [prop]: self[prop].map((o) => o.toJS()) } }
                //If navigation property 1-1 and also has value
              } else if (self[prop] && self[prop].toJS) {
                exportObj = { ...exportObj, ...{ [prop]: self[prop].toJS() } }
              } else if (Array.isArray(self[prop])) {
                //1->many, no value
                self[prop] = [];
                //1->1, no value
              } else self[prop] = null;
            }
            if (prop === keyName && isKeyMissing(displayName, self[keyName], keyName, db)) {
              exportObj = { ...exportObj, ...{ [prop]: 0 } }
            }
            if (typeof (self[prop]) === 'object' && self[prop] && self[prop]._value) exportObj = { ...exportObj, ...{ [prop]: self[prop].toString() } }
            if ((~prop.indexOf('_dt') || ~prop.indexOf('_date')) && self[prop]) {
              exportObj = { ...exportObj, ...{ [prop]: standardDateFormat(self[prop]) } }
            }
          }
          //User defined overloads
          if (overloads[displayName] && overloads[displayName].exports) {
            exportObj = { ...exportObj, ...overloads[displayName].exports(self) }
          }
          return exportObj
        }
      }
    }[name]);
    classes[prop] = nameIt(prop, props);
  }
  return classes;
}