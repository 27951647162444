import LocalStore from 'devextreme/data/local_store';
import { hookstate, useHookstate } from '@hookstate/core';

const userstore = new LocalStore({
  name: "ExtADSignup_local_user",
  key: "group_id",
  keyType: 'String'
});

const initialState = {};
const unwrappedGlobalState = hookstate(initialState);

const exceptionEnum = {
  user: (value) => {
    userstore.clear();
    userstore.insert(value);
  }
}

const setGlobalState = (obj) => {
  for (const prop in obj) exceptionEnum[prop]?.(obj[prop])
  unwrappedGlobalState.set(state => ({ ...state, ...obj }))
}

const wrapState = (globalState) => {
  return globalState.get({ noproxy: true });
}

const globalState = () => wrapState(unwrappedGlobalState);
const useGlobalState = () => wrapState(useHookstate(unwrappedGlobalState));

export { userstore, globalState, setGlobalState, useGlobalState }
