import React, { useCallback, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import { RemoveScroll } from 'react-remove-scroll';
import Slide from '@mui/material/Slide';
import Paper from '@mui/material/Paper';
import { isMobileOrTablet } from '../../utils/common';
import BackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { When } from 'react-if';

const useStyles = makeStyles((theme) => ({
  popupTitle: {
    paddingLeft: '10px',
    display: 'inline-block',
    verticalAlign: 'middle',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const PaperRemoveScroll = (props) => {
  return (
    <RemoveScroll as={Paper} {...props}>
      {props.children}
    </RemoveScroll>
  )
}

export default function DialogPreset(props) {
  const classes = useStyles();
  const { slideTransition, fullScreen = false, open, onClose, allowClose = true, ...rest } = props;
  const openRef = useRef(open);

  useEffect(() => {
    if (openRef.current !== open) {
      openRef.current = open;
      if (open === false && typeof (onClose) === 'function') onClose();
    }
  }, [open, onClose, props.id])

  const handleOnClose = useCallback(() => {
    if (typeof (onClose) === 'function') onClose();
  }, [onClose])

  return (
    <Dialog
      PaperComponent={fullScreen ? PaperRemoveScroll : undefined}
      TransitionComponent={slideTransition ? Transition : undefined}
      open={open}
      fullScreen={fullScreen}
      onClose={handleOnClose}
      {...rest}
    >
      <div style={{ position: 'relative' }}>
        <div className="blue darken-2 white-text" style={{ display: 'flex', justifyContent: isMobileOrTablet() ? 'flex-start' : 'space-between', alignItems: 'center' }}>
          <When condition={isMobileOrTablet() && allowClose}>
            <div style={{ display: 'inline-block' }}>
              <IconButton onClick={handleOnClose} >
                <BackIcon className="white-text" />
              </IconButton>
            </div>
          </When>
          <When condition={Boolean(props.title)}>
            <Typography variant="h6" className={classes.popupTitle} style={{ display: 'inline-block', verticalAlign: 'middle' }}>{props.title}</Typography>
          </When>
          <When condition={!isMobileOrTablet() && allowClose}>
            <div style={{ display: 'inline-block' }}>
              <IconButton onClick={handleOnClose}>
                <CloseIcon className="white-text" />
              </IconButton>
            </div>
          </When>
        </div>
        <Divider />
        {props.children}
      </div>
    </Dialog>
  )
}
