import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import isExternal from 'is-url-external';

const LinkDuo =forwardRef((props, ref) => {
  const {children} = props;
  return (isExternal(props.to) || (props.target === '_blank')) ?
  <a
    ref={ref}
    href={props.to}
    rel="noopener"
    {...props}
  >
    {children}
  </a>
  :
  <Link ref={ref} {...props} target={undefined}>{children}</Link>;
});
export default LinkDuo;