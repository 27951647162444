/*
 * File: c:\Core 3.0 Projects\ExtADSignup\ExtADSignup\ClientApp\src\data\overloadEntityModel.js
 * Project: c:\Core 3.0 Projects\ExtADSignup\ExtADSignup\ClientApp
 * Created Date: Monday October 21st 2019
 * Author: Walton, Timothy
 * -----
 * Last Modified: Wednesday January 15th 2020 2:53:15 pm
 * Modified By: the developer known as Walton, Timothy
 * -----
 */
//Overload the default property import and exports here
//Example in comments
const overloadedModel = {
  t_ext_access_application: {
    exports: (self) => {
      return ({
        at_all_user: null,
        expire_days: (self.expire_days || self.expire_days === 0) ? parseInt(self.expire_days, 0) : null
      })
    },
  },
  t_ext_access_approver: {
    exports: (self) => {
      return ({
        filter_id: typeof self.filter_id === 'string' ? 0 : self.filter_id
      })
    },
  },
  t_ext_access_application_filter: {
    // imports: (self) => {
    //   self.parent_filter = typeof self.filter === 'string' ? self.filter.split(";") : self.filter;
    // },
    exports: (self) => {
      return ({
        filter_id: typeof self.filter_id === 'string' ? 0 : self.filter_id,
        parent_filter: typeof self.parent_filter === 'string' ? 0 : self.parent_filter
      })
    },
  }
}
export default overloadedModel;