import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import LinearProgress from '@mui/material/LinearProgress';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

export default function DeterminateProgress({ms = 1000}) {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const diff= (100 / ms) * 100
        return Math.min(oldProgress + diff, 100);
      });
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, [ms]);

  return (
    <div className={classes.root}>
      <LinearProgress variant="determinate" value={progress} style={{position: 'absolute', bottom: '0px', left: '0px', right: '0px', borderRadius: '5px'}}/>
    </div>
  );
}