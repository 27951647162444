/*
 * File: c:\Core 3.0 Projects\Tasks\Tasks\ClientApp\src\afw\layout\NavMenu.js
 * Project: c:\Core 3.0 Projects\Tasks\Tasks\ClientApp
 * Created Date: Monday October 21st 2019
 * Author: Walton, Timothy
 * -----
 * Last Modified: Tuesday May 12th 2020 5:14:46 pm
 * Modified By: the developer known as Walton, Timothy
 * -----
 */
import React from 'react';
import List from '@mui/material/List';
import NavItem from './NavItem';
import Typography from '@mui/material/Typography';
import { useGlobalState } from '../../store/store';
import { makeStyles } from '@mui/styles'
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

const useStyles = makeStyles(() => ({
  imageArea: {
    marginTop: '4px',
    marginRight: '4px'
  },
  navText: {
    padding: '4px',
    color: '#fff',
    lineHeight: '1.2 !important'
  }
}));

export default function NavMenu({ logo, base, theme, appname }) {
  const { navData } = useGlobalState();
  const classes = useStyles();

  return (
    <div>
      <Box sx={{ backgroundColor: theme.palette.mode === 'dark' ? undefined : 'primary.main', display: 'flex', alignItems: 'center', padding: '4px', height: '48px' }}>
        <div style={{ display: 'inline-block', flexGrow: 1, textAlign: 'center' }}>
          <img src={logo} className={classes.imageArea} alt="Mississippi Department of Transportation" style={{ height: '35px' }} />
        </div>
        <Typography variant="body1" className={classes.navText}>{appname}</Typography>
      </Box>
      <Divider />
      <List style={{ paddingTop: '20px' }}>
        {
          navData?.filter(o => o.t_ext_access_navigation?.show_ind).map(o => <NavItem key={o.nav_id} row={o.t_ext_access_navigation} base={base} />)
        }
      </List>
    </div>
  );
}