import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  loadText: {
    fontWeight: '200 !important',
    display: 'inline-block',
    whiteSpace: 'no-wrap'
  },
  loadIcon: {
    fontSize: '50px',
    borderRadius: '50%',
    color: 'rgba(255,255,255,0.5)',
    padding: '10px',
    position: 'relative'
  }
}));

export default function AppLoader({ loadText = 'Loading', icon = null, color = 'indigo-darken-4', className = '', style = {} }) {
  const classes = useStyles();

  const loadElem = (
    (icon && color) ?
      <i className={`material-icons mi-${icon} ${color} ${classes.loadIcon}`}>
        <CircularProgress size={75} elementAttr={{ class: 'absolute-center' }} />
      </i>
      : <CircularProgress size={50} />
  )

  return (
    <div className={`absolute-center ${className}`} style={style}>
      {loadElem}
      <div className={classes.loadTextContainer}>
        <Typography gutterBottom variant="h6" className={classes.loadText}>{loadText}</Typography>
      </div>
    </div>
  );
}