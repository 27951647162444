import React, { useEffect, useState } from 'react';
import AppLoader from '../../components/AppLoader/AppLoader';
import { setGlobalState, useGlobalState } from '../../store/store';
import useLocalStorage from '../../utils/useLocalStorage';

let loadOnce = false;

export default function ValidationContext(props) {
  const [contextLoaded, setContextLoaded] = useState(false);
  const [validationContext, setValidationContext] = useLocalStorage('validationContext', null);
  const { children } = props;
  const { db } = useGlobalState();

  useEffect(() => {
    if (!contextLoaded && validationContext) {
      setGlobalState({ validationContext })
      setContextLoaded(true);
    }
  }, [contextLoaded, validationContext])

  useEffect(() => {
    async function fetchData() {
      if (!loadOnce && db) {
        loadOnce = true;
        const vc = await db.invoke("retrieveValidationContext");
        setGlobalState({ vc })
        setValidationContext(vc);
        setContextLoaded(true);
      }
    }
    fetchData();
  }, [setValidationContext, db])

  return !contextLoaded ? <AppLoader loadText="App Loading" /> : children;
}