/*
 * File: c:\Core 3.0 Projects\Tasks\Tasks\ClientApp\src\data\getDB.js
 * Project: c:\Core 3.0 Projects\Tasks\Tasks\ClientApp
 * Created Date: Monday October 21st 2019
 * Author: Walton, Timothy
 * -----
 * Last Modified: Monday August 10th 2020 3:54:39 pm
 * Modified By: the developer known as Walton, Timothy
 * -----
 */
import ODataContext from 'devextreme/data/odata/context';
import ODataStore from 'devextreme/data/odata/store';
import parseObjectModel from './parseEntityModel';
import preval from 'preval.macro';
import { guid, isObject } from '../utils/common';
import LocalStore from 'devextreme/data/local_store';
import overloads from './overloadEntityModel';
import isValid from 'date-fns/isValid'
import isSameSecond from 'date-fns/isSameSecond';
import parseISO from 'date-fns/parseISO';
import { userstore } from '../store/store';

const dbstore = new LocalStore({
  name: "ExtADSignup_db",
  key: "buildDate",
  keyType: 'String'
});
const buildDate = preval`module.exports = new Date().getTime()`;
const isDevelopment = process.env.NODE_ENV === 'development';

// const baseURL = isDevelopment ? "https://localhost:44388/" : "/twalton/";
// const url = isDevelopment ? "https://localhost:44388/odata" : "/twalton/odata";
const baseURL = isDevelopment ? "https://localhost:44388/" : "/";
const url = isDevelopment ? "https://localhost:44388/odata" : "/odata";
const googleSearchUrl = 'https://www.googleapis.com/customsearch/v1';
const googleKey = 'AIzaSyB7IrQ_p_GM5sjnSQAG-2x8Uu5mN3P7o3A';
const googleSearchKey = '&imgSize=medium&searchType=image&key=AIzaSyA4ufJesg5HiHQ9UTfPtYkRTnhji_a9sjM&cx=011541662930980389907:r2pkiwhwfqi';
var stateNames = [
  { name: 'ALABAMA', abbreviation: 'AL' },
  { name: 'ALASKA', abbreviation: 'AK' },
  { name: 'AMERICAN SAMOA', abbreviation: 'AS' },
  { name: 'ARIZONA', abbreviation: 'AZ' },
  { name: 'ARKANSAS', abbreviation: 'AR' },
  { name: 'CALIFORNIA', abbreviation: 'CA' },
  { name: 'COLORADO', abbreviation: 'CO' },
  { name: 'CONNECTICUT', abbreviation: 'CT' },
  { name: 'DELAWARE', abbreviation: 'DE' },
  { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC' },
  { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM' },
  { name: 'FLORIDA', abbreviation: 'FL' },
  { name: 'GEORGIA', abbreviation: 'GA' },
  { name: 'GUAM', abbreviation: 'GU' },
  { name: 'HAWAII', abbreviation: 'HI' },
  { name: 'IDAHO', abbreviation: 'ID' },
  { name: 'ILLINOIS', abbreviation: 'IL' },
  { name: 'INDIANA', abbreviation: 'IN' },
  { name: 'IOWA', abbreviation: 'IA' },
  { name: 'KANSAS', abbreviation: 'KS' },
  { name: 'KENTUCKY', abbreviation: 'KY' },
  { name: 'LOUISIANA', abbreviation: 'LA' },
  { name: 'MAINE', abbreviation: 'ME' },
  { name: 'MARSHALL ISLANDS', abbreviation: 'MH' },
  { name: 'MARYLAND', abbreviation: 'MD' },
  { name: 'MASSACHUSETTS', abbreviation: 'MA' },
  { name: 'MICHIGAN', abbreviation: 'MI' },
  { name: 'MINNESOTA', abbreviation: 'MN' },
  { name: 'MISSISSIPPI', abbreviation: 'MS' },
  { name: 'MISSOURI', abbreviation: 'MO' },
  { name: 'MONTANA', abbreviation: 'MT' },
  { name: 'NEBRASKA', abbreviation: 'NE' },
  { name: 'NEVADA', abbreviation: 'NV' },
  { name: 'NEW HAMPSHIRE', abbreviation: 'NH' },
  { name: 'NEW JERSEY', abbreviation: 'NJ' },
  { name: 'NEW MEXICO', abbreviation: 'NM' },
  { name: 'NEW YORK', abbreviation: 'NY' },
  { name: 'NORTH CAROLINA', abbreviation: 'NC' },
  { name: 'NORTH DAKOTA', abbreviation: 'ND' },
  { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP' },
  { name: 'OHIO', abbreviation: 'OH' },
  { name: 'OKLAHOMA', abbreviation: 'OK' },
  { name: 'OREGON', abbreviation: 'OR' },
  { name: 'PALAU', abbreviation: 'PW' },
  { name: 'PENNSYLVANIA', abbreviation: 'PA' },
  { name: 'PUERTO RICO', abbreviation: 'PR' },
  { name: 'RHODE ISLAND', abbreviation: 'RI' },
  { name: 'SOUTH CAROLINA', abbreviation: 'SC' },
  { name: 'SOUTH DAKOTA', abbreviation: 'SD' },
  { name: 'TENNESSEE', abbreviation: 'TN' },
  { name: 'TEXAS', abbreviation: 'TX' },
  { name: 'UTAH', abbreviation: 'UT' },
  { name: 'VERMONT', abbreviation: 'VT' },
  { name: 'VIRGIN ISLANDS', abbreviation: 'VI' },
  { name: 'VIRGINIA', abbreviation: 'VA' },
  { name: 'WASHINGTON', abbreviation: 'WA' },
  { name: 'WEST VIRGINIA', abbreviation: 'WV' },
  { name: 'WISCONSIN', abbreviation: 'WI' },
  { name: 'WYOMING', abbreviation: 'WY' }
]
async function db() {
  const hasData = await dbstore.load();
  //If data exists and build date is the same, create from Local Store
  if (hasData.length === 1 && hasData[0].buildDate === buildDate) {
    return await createDB(hasData[0].config)
  } else {
    //Clear Localstore and create from Database
    await dbstore.clear();
    return await createDB();
  }
}

async function createDB(data) {
  data = data || {};
  if (!data.entities) {
    const store = new ODataStore({ url: `${url}/createDBContext`, withCredentials: true, version: 4, beforeSend: (e) => { e.method = "POST" } });
    data.entities = await store.load();
  }
  //Entities contains Entity Framework Class and key definitions
  //EX: {t_person: {key: "person_id", keyType: "Int32"}, ...etc}
  let db = new ODataContext({
    url: url,
    version: 4,
    withCredentials: true,
    beforeSend: (e) => {
      e.timeout = 120000;
      const matchAddress = e.url.substring(e.url.lastIndexOf('/') + 1);
      //matchAddress is now 't_page_changelog', if db['t_page_changelog'] exists, we are posting to a table (insert).
      //TODO: Add levels support - https://github.com/OData/odata.net/issues/1701
      if (e.method === "POST" && db[matchAddress]) e.url += '?$expand=*';
      if (~e.url.indexOf("/odata/authenticate") || ~e.url.indexOf("/odata/reloadUser")) e.url += "?$top=10&$expand=v_ext_access_navigation($expand=t_ext_access_navigation)"
      if (~e.url.indexOf("/odata/getSignupApplications")) e.url += "?$expand=t_ext_access_application_filter($expand=SR_t_ext_access_application_filter_PARENT_FILTER($levels=max))&$select=app_description,app_id,multiple_ind,app_name,app_url,t_ext_access_application_filter"
      if (~e.url.indexOf("/odata/getAccountInfo")) e.url += "?$expand=t_ext_access_request($expand=t_ext_access_application)"
      if (typeof overloads[matchAddress]?.beforeSave?.[e.method] === 'function') {
        overloads[matchAddress].beforeSave[e.method](e.payload);
      }
    },
    errorHandler: (e) => {
      if (e.httpStatus === 401) {
        userstore.clear();
        window.location.href = "/";
      }
    },
    entities: {
      ...JSON.parse(data.entities)
    }
  });
  //OM (Object Model) contains the class property definitions for each class.
  // EX t_person { person_id: "Int32", name: "String", create_dt: "DateTime" ...etc}
  if (!data.OM) {
    data.OM = await db.invoke('retrieveOM');
    await dbstore.clear();
    await dbstore.insert({ buildDate, config: { OM: data.OM, entities: data.entities } });
  }
  db.ObjectModel = JSON.parse(data.OM);
  const tables = new RegExp(Object.keys(db.ObjectModel).sort((a, b) => b.length - a.length).join("|"), "g");
  db.Model = parseObjectModel(db.ObjectModel, db);
  db.saveChanges = async function (newObj) {
    //Can handle Array or Object
    if (Array.isArray(newObj)) {
      const newExists = newObj[0];
      if (newExists) {
        const table = newExists?.displayName;
        const valid = newExists?.toJS;
        if (valid) {
          const returnArr = await mapArrayToChanges(newObj.map(o => o._original), newObj.map(o => getJSON(o)), table, db);
          return returnArr.map(o => new db.Model[table](o));
        } else {
          throw new Error("Incorrect Data Type Provided")
        }
      } else {
        throw new Error("No data provided")
      }
    }
    if (!newObj.toJS) {
      if (isObject(newObj)) {
        const resultObj = {}
        for (let prop in newObj) {
          const o = newObj[prop]._original
          const n = getJSON(newObj[prop]);
          if (Array.isArray(o) || Array.isArray(n)) {
            const result = await applyChanges(o.map(e => getJSON(e)), n.map(e => getJSON(e)), prop, db);
            resultObj[prop] = result.map(e => new db.Model[prop](e));
          } else {
            const result = await applyChanges([o], [n], prop, db);
            resultObj[prop] = new db.Model[prop](result[0]);
          }
        }
        return resultObj;
      } else {
        throw new Error("Invalid Data Type Provided")
      }
    } else {
      //Added due to new changeTracking not detecting inserts now.
      const key = db[newObj.displayName]._key;
      let changeObj = newObj.toJS();
      //If the original key is 0, this is an insert.
      if (cleanKeys(newObj.displayName, newObj._original[key], key) === 0) newObj._original = null;
      //If dev sets key to 0 on new obj, they are indicating a delete
      else if (cleanKeys(newObj.displayName, newObj[key], key) === 0) changeObj = null
      const returnObj = await applyChanges(newObj._original, changeObj, newObj.displayName, db);
      return new db.Model[newObj.displayName](returnObj);
    }
  }
  async function applyChanges(a, b, table) {
    //Create RegExp of all possible table values
    const tables = new RegExp(Object.keys(db.Model).sort((a, b) => b.length - a.length).join("|"), "g")
    if (Array.isArray(a) && Array.isArray(b)) {
      //Change a dataset (array)
      b = await mapArrayToChanges(a, b, table);
    } else if (isObject(a) && isObject(b)) {
      //Changing objects (not Array)
      let changeObj = {};
      for (let aProp in a) {
        //Properties cannot be added
        //if RegExp Matches, it is a table prop.
        const matches = aProp.match(tables);
        if (!b.hasOwnProperty(aProp)) throw new Error("Disallowed operation: Property was added to object");
        //1->many, and exists in db
        if (Array.isArray(a[aProp]) && matches) {
          b[aProp] = await mapArrayToChanges(a[aProp], b[aProp], matches[0]);
          //Have to check old and new object for new 1->1, with old or new being null (insert/remove)
        } else if ((isObject(b[aProp]) || isObject(a[aProp])) && matches) {
          //1->1, and exists in db
          b[aProp] = await applyChanges(a[aProp], b[aProp], matches[0]);
        } else if (b[aProp] !== a[aProp]) {
          //Simple property, add to change object
          //Check Actual Date difference.
          const aParsed = parseISO(a[aProp]), bParsed = parseISO(b[aProp]);
          if (isValid(aParsed)) {
            //This is a possible Date Change
            if (!isSameSecond(aParsed, bParsed)) {
              changeObj[aProp] = b[aProp];
            }
          } else {
            changeObj[aProp] = b[aProp];
          }
        }
      }
      if (Object.keys(changeObj).length !== 0) {
        //Apply updates
        const keyName = db[table]._key;
        const keyValues = Array.isArray(keyName) ? keyName.reduce((o, keyProp, i) => Object.assign(o, { [keyProp]: b[keyProp] }), {}) : b[keyName]
        await applyUpdate(changeObj, keyValues, table);
      }
    } else if (a === null && isObject(b)) {
      //Old object did not exist, new object did exist, perform insert
      console.log("Detected 1->1 Insert")
      const newData = await applyInsert(b, db[table]._key, table);
      b = reAssignKeys(b, newData);
    } else if (b === null && isObject(a)) {
      //New Object does not exist, old object does exist, perform remove
      console.log("Detected 1->1 Remove")
      await applyDelete(a[db[table]._key], table);
    } else {
      throw new Error("Unsupported Data Types")
    }
    return b;
  }
  //Get differences in object and apply changes
  async function mapArrayToChanges(o, n, table) {
    const key = db[table]._key;
    var mapO = mapFromArray(o, key);
    var mapN = mapFromArray(n, key);
    for (let id in mapO) {
      if (id !== "undefined") {
        const keyValue = Array.isArray(key) ? key.map(keyProp => mapO[id][keyProp]) : mapO[id][key]
        const isMissingKey = isKeyMissing(table, keyValue, key);
        if (!mapN.hasOwnProperty(id) && !isMissingKey) {
          //Apply Delete mapO[id];
          const deleteObj = Array.isArray(keyValue) ? key.reduce((o, keyProp, i) => Object.assign(o, { [keyProp]: keyValue[i] }), {}) : keyValue;
          await applyDelete(deleteObj, table);
        } else if (!isMissingKey) {
          // Apply update from getChanges mapN[id];
          await applyChanges(mapO[id], mapN[id], table);
        }
      }
    }
    for (let id in mapN) {
      if (!mapO.hasOwnProperty(id)) {
        //Apply inserts mapN[id];
        const newData = await applyInsert(mapN[id], key, table);
        const ai = n.map(o => o[key]).indexOf(id);
        n[ai] = reAssignKeys(n[ai], newData);
      }
    }
    return n;
  }
  function reAssignKeys(o, n) {
    for (const prop in o) {
      if (n[prop] && isObject(n[prop]) && typeof n[prop].getMonth !== 'function') {
        if (Array.isArray(n[prop])) {
          for (let i = 0; i < o[prop].length; i++) {
            o[prop][i] = reAssignKeys(o[prop][i], n[prop][i])
          }
        } else {
          o[prop] = reAssignKeys(o[prop], n[prop])
        }
      } else if (typeof n[prop] === 'string' || typeof n[prop] === 'number') {
        o[prop] = n[prop];
      }
    }
    if (o === null) o = n;
    return o;
  }
  async function applyDelete(keyValue, table) {
    await performAction({ action: 'remove', table, key: keyValue });
  }
  async function applyInsert(obj, keyName, table) {
    let objCopy = Object.assign({}, obj);
    objCopy[keyName] = cleanKeys(table, Array.isArray(keyName) ? keyName.map(keyProp => objCopy[keyProp]) : objCopy[keyName], keyName);
    objCopy = await performAction({ action: 'insert', table, data: objCopy });
    delete objCopy['@odata.context'];
    return objCopy;
  }
  async function applyUpdate(obj, keyValue, table) {
    await performAction({ action: 'update', table, data: obj, key: keyValue });
  }
  function mapFromArray(array, prop) {
    const map = {};
    if (Array.isArray(prop)) {
      //Composite Key
      for (let i = 0; i < array.length; i++) {
        const composite_key = [];
        for (let j = 0; j < prop.length; j++) {
          composite_key.push(array[i][prop[j]]);
        }
        map[composite_key.join(";")] = array[i];
      }
    } else {
      for (let i = 0; i < array.length; i++) {
        //assign fake key if key is 0 (Not real key)
        if (array[i][prop] === 0) array[i][prop] = guid()
        map[array[i][prop]] = array[i];
      }
    }

    return map;
  }
  function getJSON(jsObj) {
    if (jsObj) {
      return (jsObj.toJS && jsObj.toJS()) || jsObj
    }
    return {};
  }

  function performAction(o) {
    if (!db[o.table]) throw new Error("Store does not exist");
    console.log(`ChangeTracker has performed: ${o.action} on table ${o.table} - key: ${o.key}. JSON data ${JSON.stringify(o.data)}`)
    return new Promise((resolve, reject) => {
      db[o.table][o.action].apply(db[o.table], o.key ? [o.key, o.data] : [o.data]).done((arg1, arg2) => {
        resolve(arg1, arg2);
      }).fail((err) => {
        reject(err);
      });
    });
  }
  //In React it's nice to set IDs to guids locally so that they are used as pseudo keys
  //This function will clean them(Only relevant on inserts); ideally you'll want to set your string keys to what they
  //would actually be rather than a guid.
  function cleanKeys(table, keyValue, key) {
    if (isKeyMissing(table, keyValue, key)) {
      return 0;
    }
    return keyValue;
  }
  function isKeyMissing(table, keyValue, key) {
    const keyEnum = { 'Int32': 'number', 'Int64': 'number', 'String': 'string' }
    return Array.isArray(key)
      ? key.some((keyCheck, i) => keyEnum[db[table]._fieldTypes[keyCheck]] !== typeof keyValue[i])
      : keyEnum[db[table]._fieldTypes[key]] !== typeof keyValue;
  }
  db.resetKeys = function (o, t) {
    const key = db[t]._key;
    o[key] = guid();//db[o.displayName]._fieldTypes[key] === 'String' ? null : 0;
    for (const prop in o) {
      if (o[prop] && isObject(o[prop]) && !isValid(o[prop])) {
        o[prop] = db.resetKeys(o[prop], prop.match(tables)[0])
      }
      if (o[prop] && Array.isArray(o[prop])) {
        for (let i = 0; i < o[prop].length; i++) {
          o[prop][i] = db.resetKeys(o[prop][i], prop.match(tables)[0])
        }
      }
    }
    return o;
  }
  return db;
}
export default db;
export { googleKey, googleSearchKey, googleSearchUrl, url, baseURL, stateNames };