import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import DeterminateProgress from './DeterminateProgress';

export default function SnackbarPreset(props){
  const {autoHideDuration = 3000, open, severity, message, ...rest} = props;

  return (
    <Snackbar autoHideDuration={autoHideDuration + 300} open={open} {...rest}>
      <Alert variant="filled" onClose={props.onClose} severity={severity}>
        {message}
        <DeterminateProgress ms={autoHideDuration}/>
      </Alert>
    </Snackbar>
  )
}
