/*
 * File: c:\Core 3.0 Projects\ExtADSignup\ExtADSignup\ClientApp\src\layout\js\DataContext.js
 * Project: c:\Core 3.0 Projects\ExtADSignup\ExtADSignup\ClientApp
 * Created Date: Tuesday January 14th 2020
 * Author: Walton, Timothy
 * -----
 * Last Modified: Monday March 9th 2020 5:52:04 pm
 * Modified By: the developer known as Walton, Timothy
 * -----
 */
import React, { useEffect } from 'react';
import db from '../../data/getDB';
import { enhancedReducer } from '../../utils/common';
import AppLoader from '../../components/AppLoader/AppLoader';
import { setGlobalState } from '../../store/store';

const initialState = {
  contextLoaded: false,
  loaded: false
}

export default function DataContext(props) {

  const [state, setState] = React.useReducer(enhancedReducer, initialState);
  const { contextLoaded, loaded } = state;
  const { children } = props;

  useEffect(() => {
    async function fetchData() {
      try {
        if (!loaded) {
          setState({ loaded: true })
          const _db = await db();
          setGlobalState({ db: _db })
          setState({ contextLoaded: true })
        }
      } catch (err) {
        setState({ contextLoaded: true, userNotFound: true })
      }
    }
    fetchData();
  }, [loaded])
  return (
    <>
      {
        !contextLoaded ? <AppLoader loadText="App Loading" /> : children
      }
    </>
  )
}