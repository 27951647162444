/*
 * File: c:\Core 3.0 Projects\ExtADSignup\ExtADSignup\ClientApp\src\afw\layout\Layout.js
 * Project: c:\Core 3.0 Projects\ExtADSignup\ExtADSignup\ClientApp
 * Created Date: Monday October 21st 2019
 * Author: Walton, Timothy
 * -----
 * Last Modified: Monday August 10th 2020 12:21:37 pm
 * Modified By: the developer known as Walton, Timothy
 * -----
 */
import React, { useCallback, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import NavMenu from '../../components/Navigation/NavMenu';
import { enhancedReducer, environment, findBase } from '../../utils/common';
import { setGlobalState, useGlobalState } from '../../store/store';
import { useLocation, useHistory } from 'react-router';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/styles';
import LightMode from '@mui/icons-material/LightMode';
import DarkMode from '@mui/icons-material/DarkMode';
import { makeStyles } from '@mui/styles'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import LogOut from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Logo from '../../images/mdotlogo.png';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import SignIn from '../../views/SignIn';
import { ErrorBoundary } from 'react-error-boundary';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles((theme) => ({
  imageArea: {
    height: '33px',
    marginRight: '5px',
    marginTop: '4px'
  },
  button: {
    margin: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      minWidth: '32px !important',
      paddingLeft: '8px !important',
      paddingRight: '8px !important',
      "& .MuiButton-startIcon": {
        margin: 0
      }
    }
  },
  buttonText: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  }
}));

const initialState = {
  mobileOpen: false,
  notAuth: false,
  activeTitle: "Home",
  pathName: '',
  environment: environment === 'Development' ? 'DEMO' : 'PROD'
}

const ErrorFallback = () => {
  return (
    <Box className={`absolute-center`} sx={{ ml: 'auto', mr: 'auto', mt: '10px', maxWidth: '500px', width: '100%', position: 'relative' }}>
      <Paper sx={{ p: '0px 20px', textAlign: 'left' }}>
        <Typography variant="h5" sx={{ pt: 1 }}>
          Something went wrong
        </Typography>
        <Typography variant="h6" gutterBottom>
          An error report has been sent. If this issue persists, please email us at <a href="mailto: servicedesk@mdot.ms.gov?subject=MDOT External Account error">servicedesk@mdot.ms.gov</a>
        </Typography>
        <Divider />
        <Box sx={{ textAlign: 'right' }}>
          <Button onClick={() => window.location.reload()} sx={{ m: 1, display: 'inline-block' }}>
            Refresh window
          </Button>
        </Box>

      </Paper>
    </Box>
  )
}

const Layout = ({ handleSetTheme, children, appName, navWidth }) => {
  const [state, setState] = React.useReducer(enhancedReducer, initialState);
  const { user, navData, signOut } = useGlobalState();
  const { pathname } = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();

  const toggleHidden = () => (open) => {
    setState({ mobileOpen: typeof open === 'boolean' ? open : !state.mobileOpen })
  };

  const baseName = findBase(pathname);

  useEffect(() => {
    window.onresize = () => setState({ mobileOpen: false })
    //Old ComponentWillUnMount
    return () => {
      window.onresize = null;
    }
  }, []);

  useEffect(() => {
    setState({ mobileOpen: false })
    if (state.pathName !== baseName) {
      setGlobalState({ toolbarItems: [], showExtraMenu: false })
      setState({ pathName: baseName });
    }
  }, [baseName, state.pathName, user, history])

  const handleSignOut = useCallback(async () => {
    await signOut();
    history.push("/");
  }, [signOut, history])

  const page = navData?.find(o => o?.t_ext_access_navigation?.path === state.pathName)?.t_ext_access_navigation;
  const title = page?.title;
  const showNav = navData && navData.filter(o => o.t_ext_access_navigation?.show_ind).length > 1;
  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position={"fixed"}
        elevation={0}
        sx={{
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: theme.palette.divider,
          width: showNav ? { md: `calc(100vw - ${navWidth}px)` } : undefined,
          ml: { sm: `${navWidth}px` },
        }}>
        <Toolbar variant="dense">
          {
            showNav && (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 1, display: { md: 'none' } }}
                onClick={toggleHidden(true)}
              >
                <MenuIcon />
              </IconButton>
            )
          }
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', paddingTop: '3px', paddingBottom: '3px' }}>
            {title || "Not Found"}
            {
              environment === 'Development' && (
                <span> - <span style={{ borderRadius: '5px', borderColor: theme.palette.warning[400], color: theme.palette.warning[400], borderWidth: 1, borderStyle: 'solid', padding: '3px' }}>DEMO</span></span>
              )
            }
          </Typography>
          <div style={{ whiteSpace: 'nowrap' }}>
            <Tooltip title="Switch Theme">
              <Button className={classes.button} color={theme.palette.mode === 'dark' ? undefined : 'inherit'} onClick={handleSetTheme} variant="outlined" sx={{ mr: 1 }} startIcon={theme.palette.mode === 'dark' ? <LightMode className={classes.iconSpacing} /> : <DarkMode className={classes.iconSpacing} />}>
                <span className={classes.buttonText}>{theme.palette.mode === 'dark' ? 'Switch to Light Mode' : 'Switch to Dark Mode'}</span>
              </Button>
            </Tooltip>
            {
              Boolean(user) && user.user_id !== 'Anonymous' && (
                <Tooltip title="Sign Out">
                  <Button className={classes.button} color={theme.palette.mode === 'dark' ? undefined : 'inherit'} onClick={handleSignOut} variant="outlined" startIcon={<LogOut className={classes.iconSpacing} />}>
                    <span className={classes.buttonText}>Sign Out</span>
                  </Button>
                </Tooltip>
              )
            }
          </div>
        </Toolbar>
      </AppBar>
      {
        showNav && (
          <Box
            component="nav"
            sx={{ width: { md: navWidth }, flexShrink: { md: 0 } }}
            aria-label="navigation"
          >
            <Drawer
              container={container}
              variant="temporary"
              open={state.mobileOpen}
              onClose={toggleHidden(false)}
              ModalProps={{ keepMounted: true }}
              sx={{
                display: { xs: 'block', md: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: navWidth, borderRight: 'none' },
              }}
            >
              <NavMenu logo={Logo} appname={appName} base={baseName} theme={theme} />
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: 'none', md: 'block', classes: { paper: { borderRight: 'none' } } },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: navWidth, borderRight: 'none' },
              }}
              open
            >
              <NavMenu logo={Logo} appname={appName} base={baseName} theme={theme} />
            </Drawer>
          </Box>
        )
      }
      <Box
        component="main"
        sx={{ flexGrow: 1, width: { md: `calc(100% - ${navWidth}px)` }, position: 'relative', minHeight: 'calc(100vh - 48px)', marginTop: '48px' }}
      >
        {((!Boolean(user) || user?.group_id === 'Anonymous') && baseName !== '/createaccount' && baseName !== '/verify' && baseName !== '/reset' && baseName !== '/formsubmitted') ? <SignIn /> : <ErrorBoundary FallbackComponent={ErrorFallback}>
          {children}
        </ErrorBoundary>}
      </Box>
    </Box>
  )
}
export default Layout;