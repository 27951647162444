import React, { useCallback, useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import { IMaskInput } from 'react-imask';
import { convertFalseyToString, convertIfNumber, guid } from '../../utils/common';

const TextMask = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  const { mask, useMaskedValue, definitions, maskRef } = props.maskconfig;
  return (
    <IMaskInput
      {...other}
      mask={mask}
      unmask={!Boolean(useMaskedValue)}
      definitions={{
        '#': /[1-9]/,
        ...definitions
      }}
      ref={maskRef}
      inputRef={ref}
      onAccept={(value) => {
        onChange({ target: { dataset: { id: props.id }, value } });
      }}
      overwrite
    />
  );
});

const MuiTextBox = React.forwardRef((props, ref) => {
  const { value: propsValue, onChange, onBlur, inputProps, InputProps, id, enterKey, ...rest } = props;
  const [value, setValue] = useState(convertFalseyToString(propsValue));
  const maskRef = useRef(null), valueRef = useRef(value), clearRef = useRef(false), typeRef = useRef(props.type);

  // const imperatives = useCallback(() => ({
  //   refresh: () => loadData(dataSource),
  //   setState: setState
  // }), [loadData, dataSource]);

  // useImperativeHandle(ref, imperatives, [imperatives]);

  let newInputProps = {
    ...InputProps, onKeyDown: (e) => {
      if (typeof InputProps?.onKeyDown === 'function') InputProps.onKeyDown(e);
      const key = e.keyCode || e.charCode;
      if (key === 8 || key === 46) clearRef.current = true;
      if (key === 13 && typeof enterKey === 'function') enterKey(e);
    }, onKeyPress: (e) => {
      if (typeof InputProps?.onKeyDown === 'function') InputProps.onKeyPress(e);
      if (props.type === "number") {
        const key = e.keyCode || e.charCode;
        if (key < 48 || key > 57) {
          e.preventDefault();
        }
      }
    }
  };

  if (props.type === "number" || props.type === "decimal") {
    newInputProps.inputMode = 'numeric';
    newInputProps.pattern = '[0-9]*';
  }

  if (inputProps?.maskconfig) {
    inputProps.maskconfig.maskRef = maskRef;
    newInputProps = { ...newInputProps, inputComponent: TextMask }
  }

  const handleChange = useCallback((e) => {
    if (e.target.value !== "" || (clearRef.current && e.target.value === "")) {
      setValue(e.target.value);
      valueRef.current = e.target.value;
      onChange?.(convertIfNumber(typeRef.current, e));
    }
    clearRef.current = false;
  }, [onChange]);

  const handleBlur = useCallback((e) => {
    if (maskRef.current) {
      onBlur?.({ target: { value: maskRef.current.maskValue, dataset: { id } } });
    } else {
      onBlur?.(convertIfNumber(typeRef.current, e));
    }
    clearRef.current = false;
  }, [onBlur, id]);

  useEffect(() => {
    if (propsValue !== valueRef.current && !(propsValue === null && valueRef.current === '')) {
      valueRef.current = propsValue;
      setValue(convertFalseyToString(propsValue))
    };
  }, [propsValue])

  return (
    <TextField
      id={guid()}
      inputRef={ref}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      inputProps={{ ...inputProps, 'data-id': id }}
      InputProps={newInputProps}
      type={props.type === 'decimal' ? "number" : props.type}
      {...rest}
    />
  );
})
export default MuiTextBox;