/*
 * File: c:\Core 3.0 Projects\ExtADSignup\ExtADSignup\ClientApp\src\afw\layout\App.js
 * Project: c:\Core 3.0 Projects\ExtADSignup\ExtADSignup\ClientApp
 * Created Date: Tuesday January 14th 2020
 * Author: Walton, Timothy
 * -----
 * Last Modified: Thursday August 20th 2020 1:14:09 pm
 * Modified By: the developer known as Walton, Timothy
 * -----
 */
import React, { useEffect, useCallback, useRef } from 'react';
import Router from '../../navigation/Router';
import DataContext from './DataContext';
import ErrorContext from './ErrorContext';
import Layout from './Layout';
import CssBaseline from '@mui/material/CssBaseline';
import { useGlobalState } from '../../store/store';
import register from '../../utils/registerServiceWorker';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getDesignTokens, getThemedComponents } from './Theme';
import useLocalStorage from '../../utils/useLocalStorage';
import ValidationContext from './ValidationContext';
import UserContext from './UserContext';

import '../css/material-icons.css';
import '../css/common.css';

export default function App(props) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const globalState = useGlobalState();
  const globalStateRef = useRef(globalState);
  const [mode, setMode] = useLocalStorage('theme', prefersDarkMode ? 'dark' : 'light')

  const theme = React.useMemo(() => {
    let brandedTheme = createTheme(getDesignTokens(mode))
    return createTheme(brandedTheme, {
      components: { ...getThemedComponents(brandedTheme).components }
    });
  }, [mode]);

  //PWA prompt
  const history = useHistory();

  useEffect(() => {
    if (navigator.vendor !== 'Apple Computer, Inc.') {
      register(globalStateRef.current);
    }
  }, []);

  useEffect(() => {
    let hidden;
    let visibilityChange;
    if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }
    const onVisibilityChange = () => {
      if (!document[hidden]) register(null, true);
    }
    document.addEventListener(visibilityChange, onVisibilityChange);
    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange);
    }
  }, [])

  useEffect(() => {
    const unlisten = history.listen(() => {
      register(null, true);
    });
    return () => {
      unlisten();
    }
  }, [history])

  const handleSetTheme = useCallback(() => {
    setMode((prevMode) => prevMode === 'dark' ? 'light' : 'dark');
  }, [setMode])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <DataContext>
        <ValidationContext>
          <UserContext>
            <ErrorContext>
              <Layout name={props.name} logo={props.logo} appName={props.appName} navWidth={props.navWidth} enableSignalR={props.enableSignalR} handleSetTheme={handleSetTheme}>
                <Router />
              </Layout>
            </ErrorContext>
          </UserContext>
        </ValidationContext>
      </DataContext>
    </ThemeProvider>
  );
}