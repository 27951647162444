/*
 * File: c:\Core 3.0 Projects\ExtADSignup\ExtADSignup\ClientApp\src\navigation\AsyncComponent.js
 * Project: c:\Core 3.0 Projects\ExtADSignup\ExtADSignup\ClientApp
 * Created Date: Monday October 21st 2019
 * Author: Walton, Timothy
 * -----
 * Last Modified: Monday July 27th 2020 4:52:21 pm
 * Modified By: the developer known as Walton, Timothy
 * -----
 */
import React, { Component } from "react";
import AppLoader from "../AppLoader/AppLoader";
import { ReactComponent as NotFound } from '../../images/404.svg'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  alignMiddle: {
    verticalAlign: 'middle',
    display: 'inline-block'
  },
  centerArea: {
    textAlign: 'center',
    padding: '20px 0',
    width: '100%',
    maxWidth: '400px'
  },
  iconArea: {
    width: '120px',
    height: '120px',
    display: 'block',
    margin: 'auto',
    paddingBottom: '10px',
    fill: theme.palette.text.primary
  }
}));

const NotFoundComponent = () => {
  const classes = useStyles();
  return (
    <Paper className={`absolute-center ${classes.centerArea}`}>
      <Typography variant="h3" style={{ padding: '24px 0', fontWeight: 200 }}>
        <NotFound className={classes.iconArea} />
        No Internet
      </Typography>
      <Typography gutterBottom variant="body1">
        <span className={classes.alignMiddle}>It looks like we lost your connection. Try </span>
        <Button className={classes.alignMiddle} onClick={() => window.location.reload(true)}>
          Reloading
        </Button>
      </Typography>
    </Paper>
  )
}

export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    state = { notFound: false, component: null }
    mounted = false
    async componentDidMount() {
      try {
        this.mounted = true;
        const { default: component } = await importComponent();
        if (this.mounted) {
          this.setState({
            component: component
          });
        }
      } catch (err) {
        if (this.mounted) this.setState({ notFound: true });
      }
    }
    componentWillUnmount() {
      this.mounted = false;
    }
    render() {
      const C = this.state.component;
      return C ? <C {...this.props} /> : this.state.notFound ? <NotFoundComponent /> : <AppLoader />;
    }
  }
  return AsyncComponent;
}